.statistics-data {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  margin-bottom: 0;
  line-height: 1.2;
}

.statistics-data > .ant-statistic-title {
  font-size: 11px;
  margin-bottom: 0;
}

.statistics-data .ant-statistic-content-value-int {
  font-weight: 600;
  font-size: 15px;
  margin-inline-start: 0;
}

.important-stat .ant-statistic-content-value-int {
  font-weight: 700;
  font-size: 16px;
  color: var(--hs-violet);
}

.panel-heading {
  font-size: 13px;
  font-weight: 600;
  color: #333;
}
.campaign-analytic-sprites {
  background-repeat: no-repeat;
  background: url(../../assets/svg/static-icons.svg);
  width: 35px;
  min-width: 25px;
  height: 35px;
  min-height: 53px;
  border-radius: 4px;
  transform: translate(0%, 0%) scale(1);
  background-size: 34rem 36rem;
}
.ci-sent {
  background-position: -277px -299px;
}
.ci-totalProcessed {
  background-position: -170px -84px;
}
.ci-success {
  background-position: -373.8px -84px;
}
.stepOneAuthorizeIcon {
  background-position: -509.8px -121px;
}
.stepTwoAuthorizeIcon {
  background-position: -39.4px -182px;
  height: 51px !important;
}
.ci-failure {
  background-position: -305px -84px;
}
.ci-skipped {
  background-position: -237px -84px;
}
.ci-inProgress {
  background-position: -109px -84px;
}

.card-analytics {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin: 0 auto;
  transition: all 0.2s ease;
}

.card-analytics:hover {
  transform: translateY(-1px);
}

.stats-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
}

.stats-card {
  flex: 0 0 calc(16.66% - 14px);
  min-width: 140px;
}

@media (max-width: 1200px) {
  .stats-card {
    flex: 0 0 calc(33.33% - 12px);
  }
}

@media (max-width: 992px) {
  .stats-card {
    flex: 0 0 calc(33.33% - 12px);
  }
}

@media (max-width: 768px) {
  .stats-card {
    flex: 0 0 calc(50% - 8px);
  }
}

@media (max-width: 576px) {
  .stats-card {
    flex: 0 0 calc(50% - 8px);
  }

  .stats-cards-container {
    gap: 8px;
  }
}
.card-row {
  width: 100%;
}
.campaign-Chart {
  border-radius: 12px;
  margin: auto;
}
.error-codes {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 20px;
  height: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.errorCodes > ul > li {
  list-style: 1rem;
}
.campaignErrorsTable thead .ant-table-cell {
  background-color: #fff !important;
  /* color: white !important; */
  font-size: 12px;
  font-weight: 400 !important;
}
.campaignErrorsTable .ant-table-container {
  box-shadow: none;
  padding: 3rem;
}
.campaignErrorsTable.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 14px 1px;
}
.campaignErrorsTable.ant-table-wrapper table {
  border: none;
}
.campaignErrorsTable .ant-table-content {
  padding: 2rem;
}
.campaignErrorsTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.campaignErrorsTable.ant-table-wrapper .ant-table-thead > tr > th {
  text-align: center;
}
.campaignErrorsTable .ant-table-tbody > tr > td {
  text-align: center;
  width: 3rem;
  max-width: 3rem;
  /* color: #6a707e; */
}
.campaignErrorsTable .ant-table-tbody > tr.ant-table-row:hover > td {
  color: #605bff;
  background-color: inherit;
}
.campaignErrorsTable.ant-table-wrapper .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 1px solid #ededed !important;
}
.campaignDetailMenu {
  width: 220px;
}
.campaignDetailMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
.campaignDetailMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after,
.campaignDetailMenu.ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
  border-bottom-width: 0px !important;
  transition: 0s !important;
}
.campaignDetailMenu.ant-menu-horizontal {
  border-bottom: 0px;
  background-color: #fff !important;
  border-radius: 12px;
}
.campaignDetailMenu.ant-menu .ant-menu-title-content {
  font-size: 12px;
}
.campaignDetailMenu.ant-menu-horizontal .ant-menu-item {
  padding-inline: 0px;
}
.colorDefine {
  height: 25px;
  width: 25px;
  border-radius: 6px;
}
.error-codes-list.ant-list-split .ant-list-item {
  border-block-end: 0px;
}
.errorCodesListContainer {
  max-height: 600px;
  overflow: auto;
}
.campaign-menu-button {
  border-inline: 0px !important;
  border-width: 0px;
  height: 32px !important;
  display: flex;
  align-items: center;
  font-size: 13px !important;
  padding: 0 16px !important;
}
.anti-hover:hover {
  color: black !important;
}
.campaign-menu {
  border-radius: 6px !important;
  background-color: #f5f5f5 !important;
  display: flex;
  align-items: center;
}
.campaign-menu .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.overview-btn {
  background-color: var(--hs-violet) !important;
  color: #fff !important;
}
.compact-tabs .ant-radio-button-wrapper {
  padding: 0 12px !important;
  line-height: 1.3 !important;
}

.compact-tabs {
  margin-bottom: 0 !important;
  padding: 2px !important;
}

/* Styles for the refresh button */
.refresh-icon .compact {
  width: 20px;
  height: 20px;
  background-size: 28rem 30rem;
  margin-top: 2px;
}

.campaignActionContainer {
  min-height: 5dvh;
}

.campaignActionTitleContainer {
  height: 100%;
}

.reportCardRoot {
  width: 14rem;
  height: 7rem;
  box-shadow: none !important;
}

/* Styles for campaign message logs */
.campaign-message-logs {
  width: 100%;
}

/* Message logs panel styling */
.message-logs-panel {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

/* Customize MessageLogs component inside campaign analytics */
.campaign-message-logs .message-logs-container {
  margin: 0 !important;
  padding: 0 !important;
}

.campaign-message-logs .message-logs-container > div {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Remove extra margins from the filter panel */
.campaign-message-logs .filter-panel {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}
