.campaignOverviewIcon {
  height: 42px !important;
  width: 42px !important;
  background-size: 38rem;
  flex-shrink: 0;
}

/* Custom campaign metrics styles */
.campaign-metric-title {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 2px;
}

.campaign-metric-value {
  font-size: 14px;
  font-weight: 800;
  color: #000;
}

.campaign-metric-box {
  min-width: 200px;
}

.campaign-create-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #605bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.campaign-create-button:hover {
  background-color: #4a45d1;
}

.totalCampaignsIcon {
  background-position: 33.5% 17.3%;
}

.messagesSentIcon {
  background-position: 3.6% 17.5%;
}

.h-90 {
  height: 90%;
}

.sendRocketWhite {
  background-position: 65.3% 9.7%;
}

.campaignOverviewTable {
  overflow-x: auto;
  white-space: nowrap;
}

.campaignOverviewTable > div > div > div > .ant-table-container {
  box-shadow: none !important;
}

.campaignOverviewTable > div > div > div > .ant-table-container > .ant-table-tbody {
  position: unset !important;
}

.campaignOverviewTable .ant-table-thead > tr > th {
  background-color: #f8f9fa;
  font-weight: 600;
  border-bottom: 1px solid #e9ecef !important;
}

.overviewTableCells {
  color: #030229 !important;
  font-size: 13px;
  font-weight: 500;
  background-color: white !important;
  border-bottom: 1px solid #f0f0f5 !important;
  padding: 8px 8px !important;
}

.campaignTitle {
  width: 100px;
}

.navigateToCampaignButton {
  height: 3rem;
  width: 9rem;
}

.ant-table-thead > tr > .overviewTableCells {
  font-size: 13px;
  border-bottom: 1px solid #e9ecef !important;
  font-weight: 600 !important;
  color: #212529 !important;
}

.ant-table-thead > tr > .overviewTableCells::before {
  display: none !important;
}

.overviewTableRow {
  cursor: pointer;
  transition: all 0.2s ease;
}

.overviewTableRow:hover {
  background-color: #f5f8ff;
}

.campaignBadge {
  text-transform: lowercase;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
}

/* Increase table height */
.ant-table-wrapper {
  min-height: calc(100vh - 250px);
}

.campaignBadge::first-letter {
  text-transform: uppercase;
}

.immediateBadge {
  background-color: rgba(var(--badge-light-blue), 0.1);
  color: rgba(var(--badge-light-blue));
}

.scheduledBadge {
  background-color: rgba(var(--badge-light-red), 0.1);
  color: rgba(var(--badge-light-red));
}

.overviewTableRow:hover > .overviewTableCells,
.overviewTableRow:hover > .overviewTableCells > .cellInnerText,
.overviewTableRow:hover > .overviewTableCells > .cellInnerText > *,
.overviewTableRow:hover > .overviewTableCells > .cellInnerText > * > * {
  color: var(--hs-violet) !important;
}

.overviewTableRow:hover > .overviewTableCells > .cellInnerText > .previewEyeIcon {
  visibility: visible;
}

.sendingOrScheduledStatus {
  background-color: rgba(var(--badge-light-green), 0.1) !important;
  color: rgb(var(--badge-light-green)) !important;
}

.sentStatus {
  background-color: var(--hs-v-light-blue) !important;
  color: var(--hs-blue) !important;
}

.pendingStatus {
  background-color: rgba(var(--badge-light-grey), 0.1) !important;
  color: rgb(var(--badge-light-grey)) !important;
}

.cellInnerText {
  color: #030229 !important;
  font-size: 14px;
  font-weight: 500;
}

.rocketWithAirIcon {
  height: 5rem !important;
  width: 3rem !important;
  background-position: -2.5rem -32rem;
  background-size: 40rem;
}

.metricContainer {
  height: 120px !important;
  width: 320px !important;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 16px;
  transition: all 0.2s ease;
  margin: 0 15px;
}

.metrics-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.metricContainer:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.previewEyeIcon {
  visibility: hidden;
  background-position: 67.5% 18.8%;
}

.createdUser {
  height: 37px;
  width: 37px;
  border: 2px solid gold;
}

.metric-content-text {
  font-size: 18px !important;
  margin-bottom: 8px;
  color: #333;
  font-weight: 600 !important;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.activityDetailList {
  width: 100%;
  max-height: 40vh !important;
  overflow: auto;
}

@media (max-width: 768px) {
  .activityDetailsModal.ant-modal .ant-modal-content {
    padding: 15px;
  }
  .activityDetailItemText > span {
    font-size: 14px !important;
  }
}
