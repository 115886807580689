/* MessageLogs specific styles */
.message-logs-table {
  overflow-x: auto;
  white-space: nowrap;
}

.message-logs-table > div > div > div > .ant-table-container {
  box-shadow: none !important;
}

.message-logs-table > div > div > div > .ant-table-container > .ant-table-tbody {
  position: unset !important;
}

.message-logs-table .ant-table-thead > tr > th {
  background-color: #f8f9fa;
  font-weight: 600;
  border-bottom: 1px solid #e9ecef !important;
}

.message-logs-cells {
  color: #030229 !important;
  font-size: 13px;
  font-weight: 500;
  background-color: white !important;
  border-bottom: 1px solid #f0f0f5 !important;
  padding: 8px 8px !important;
}

.ant-table-thead > tr > .message-logs-cells {
  font-size: 13px;
  border-bottom: 1px solid #e9ecef !important;
  font-weight: 600 !important;
  color: #212529 !important;
}

.ant-table-thead > tr > .message-logs-cells::before {
  display: none !important;
}

.message-logs-row {
  cursor: pointer;
  transition: all 0.2s ease;
}

.message-logs-row:hover {
  background-color: #f5f8ff;
}

.message-logs-row:hover > .message-logs-cells,
.message-logs-row:hover > .message-logs-cells > .cell-inner-text,
.message-logs-row:hover > .message-logs-cells > .cell-inner-text > *,
.message-logs-row:hover > .message-logs-cells > .cell-inner-text > * > * {
  color: var(--hs-violet) !important;
}

.message-logs-row:hover > .message-logs-cells > .cell-inner-text > .preview-eye-icon {
  visibility: visible;
}

.cell-inner-text {
  color: #030229 !important;
  font-size: 14px;
  font-weight: 500;
}

/* Style for friendly name display */
.friendly-name {
  font-weight: 600;
  cursor: pointer;
}

/* Show the original phone number in tooltip */
.ant-tooltip-inner {
  max-width: 300px;
  word-wrap: break-word;
}

.preview-eye-icon {
  background-position: 67.5% 18.8%;
}

/* Message status badges */
.message-status-badge {
  text-transform: lowercase;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
}

.message-status-badge::first-letter {
  text-transform: uppercase;
}

.status-delivered {
  background-color: rgba(0, 200, 83, 0.1) !important;
  color: rgb(0, 200, 83) !important;
}

.status-failed {
  background-color: rgba(255, 71, 87, 0.1) !important;
  color: rgb(255, 71, 87) !important;
}

.status-received {
  background-color: rgba(75, 126, 228, 0.1) !important;
  color: rgb(75, 126, 228) !important;
}

.status-sent {
  background-color: rgba(77, 182, 172, 0.1) !important;
  color: rgb(77, 182, 172) !important;
}

.status-queued {
  background-color: rgba(108, 117, 125, 0.1) !important;
  color: rgb(108, 117, 125) !important;
}

.status-read {
  background-color: rgba(94, 53, 177, 0.1) !important;
  color: rgb(94, 53, 177) !important;
}

.status-deleted {
  background-color: rgba(210, 77, 87, 0.1) !important;
  color: rgb(210, 77, 87) !important;
}

.status-pending {
  background-color: rgba(150, 150, 150, 0.1) !important;
  color: rgb(150, 150, 150) !important;
}

/* Message metrics styles */
.message-metric-box {
  min-width: 200px;
}

.message-metric-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 2px;
}

.message-metric-value {
  font-size: 24px;
  font-weight: 800;
  color: #000;
}

/* Increase table height */
.message-logs-container .ant-table-wrapper {
  min-height: calc(100vh - 280px);
}

/* Pagination styling */
.message-logs-container .ant-table-footer {
  padding: 16px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}

.pagination-footer {
  background-color: #fff; /* Adjust if needed */
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.pagination-footer .ant-btn {
  min-width: 80px;
}

.pagination-footer .ant-btn[disabled] {
  opacity: 0.5;
}

.pagination-footer .ant-btn-primary {
  background-color: var(--hs-violet);
  border-color: var(--hs-violet);
}

/* Make outline persistent on focus */
.persistent-outline-input:focus,
.persistent-outline-input:hover,
.persistent-outline-input-focused {
  border-color: #d9d9d9 !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
}

/* Table header styling */
.message-logs-table .ant-table-thead th {
  position: relative;
  transition: background-color 0.2s;
}

.message-logs-table .ant-table-thead th:hover {
  background-color: #f0f0f0;
}

/* Compact mode styles */
.message-logs-table.compact-mode {
  table-layout: fixed;
  width: 100%;
}

.message-logs-table.compact-mode .ant-table-container {
  width: 100%;
}

.message-logs-table.compact-mode .ant-table-content {
  width: 100%;
}

.message-logs-table.compact-mode table {
  width: 100% !important;
  table-layout: fixed;
}

/* Equal width distribution for compact mode */
.message-logs-table.compact-mode .ant-table-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Remove any margin/padding between cells to reduce white space */
.message-logs-table.compact-mode .ant-table-cell {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

/* Service name badge styling */
.service-name-badge {
  font-size: 10px;
  font-weight: 600;
  background-color: rgba(72, 72, 176, 0.1);
  color: rgb(72, 72, 176);
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 6px;
  white-space: nowrap;
}

/* Campaign message logs filter styling */
.campaign-logs-filter-row .ant-form-item {
  width: 100%;
  margin-bottom: 0;
}

.campaign-logs-filter-row .ant-select {
  width: 100%;
}

/* Campaign Message Logs Styling */
/* Ensure consistent margin and padding between components */
.message-logs-wrapper .message-logs-container {
  margin: 0 !important;
  padding: 0 !important;
}

/* Ensure filter panel aligns with campaign details card */
.message-logs-wrapper .message-logs-container .filter-panel {
  margin-top: 0 !important;
}

/* Adjust the message logs table to align properly */
.message-logs-wrapper .message-logs-table {
  margin-top: 0;
}

/* Remove any extra padding that could cause misalignment */
.message-logs-wrapper .message-logs-container > div {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Campaign Message Logs Filter Control */
/* Force-show status and error code filters for campaign logs */
.campaign-message-logs .filter-status,
.campaign-message-logs .filter-error-code {
  display: block !important;
  visibility: visible !important;
  min-width: 200px !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
}

/* Style for the inputs inside */
.campaign-message-logs .filter-status .ant-select,
.campaign-message-logs .filter-error-code .ant-input {
  display: block !important;
  visibility: visible !important;
  width: 100% !important;
}

/* Make sure the form items are properly displayed */
.campaign-message-logs .filter-status .ant-form-item,
.campaign-message-logs .filter-error-code .ant-form-item {
  width: 100% !important;
  margin-bottom: 0 !important;
  display: block !important;
}

/* Override any hidden display properties */
.campaign-message-logs [name="status"],
.campaign-message-logs [name="errorCode"] {
  display: block !important;
}

/* Campaign filters styling for status filter */
.filter-status .ant-select-selector {
  border-color: #d9d9d9 !important;
  border-radius: 4px !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
}

/* Campaign filters styling for error code filter */
.filter-error-code .ant-input {
  border-color: #d9d9d9 !important;
  border-radius: 4px !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
}
.messageRange .ant-picker-range:hover {
  border-color: #605bff !important;
}
.paginationLogsButton:hover {
  background-color: #605bff !important;
}
