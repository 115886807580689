/* Styling for Automation Logs page */

/* Ensure consistent margin and padding between components */
.message-logs-wrapper .message-logs-container {
  margin: 0 !important;
  padding: 0 !important;
}

/* Ensure filter panel aligns with automation details card */
.message-logs-wrapper .message-logs-container .filter-panel {
  margin-top: 0 !important;
}

/* Adjust the message logs table to align properly */
.message-logs-wrapper .message-logs-table {
  margin-top: 0;
}

/* Remove any extra padding that could cause misalignment */
.message-logs-wrapper .message-logs-container > div {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}